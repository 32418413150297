export const genres = [
  { id: 'techno', name: 'Techno' },
  { id: 'hiphop', name: 'HipHop' },
  { id: 'pop', name: 'Pop' },
  { id: 'trance', name: 'Trance' },
  { id: 'house', name: 'House' }
];

export const languages = [
  { id: 'en', name: 'EN' },
  { id: 'de', name: 'DE' },
];

export const categories = [
  { id: 'timer', name: 'Timer' },
  { id: 'music', name: 'Music' },
];
