import filter from 'lodash/filter';

export const roles = [
  { id: 'admin', name: 'Admin' },
  { id: 'coach', name: 'Coach' },
  { id: 'head_coach', name: 'Head Coach' },
  { id: 'customer_support_manager', name: 'Customer Support Manager' },
  { id: 'customer_support', name: 'Customer Support' },
  { id: 'tech_ops', name: 'Tech Ops' },
];

export const segments = [{ id: 'test', name: 'Test' }];

export const locales = [
  { id: 'en', name: 'English' },
  { id: 'de', name: 'German' },
];

export const genders = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
];

export const providers = [
  { id: 'usc', name: 'usc', formattedName: 'Urban Sports Club', external: true },
  { id: 'gympass', name: 'gympass', formattedName: 'Gympass', external: true },
  { id: 'classpass', name: 'classpass', formattedName: 'ClassPass', external: true },
  { id: 'factory', name: 'factory', formattedName: 'Factory', external: true },
  { id: 'onefit', name: 'onefit', formattedName: 'Onefit', external: true },
  { id: 'qualitrain', name: 'qualitrain', formattedName: 'Qualitrain', external: true },
  { id: 'mfc', name: 'mfc', formattedName: 'My Fitness Card', external: true },
  { id: 'atlantic', name: 'atlantic', formattedName: 'Atlantic Labs', external: true },
  { id: 'internal', name: 'internal', formattedName: 'Internal', external: false },
  { id: 'family', name: 'family', formattedName: 'Family', external: false },
  { id: 'coach', name: 'coach', formattedName: 'Coach Tryout', external: false },
  { id: 'foh', name: 'foh', formattedName: 'foh', external: false },
];

export const companies = [
  { id: 'sofatutor', name: 'Sofatutor' },
  { id: 'beat81_tryout', name: 'BEAT81 Tryout' },
  { id: 'beat81_onboarding', name: 'BEAT81 Onboarding' },
  { id: 'doccheck', name: 'Doccheck' },
  { id: 'john_reed', name: 'John Reed' },
];

export const externalProviders = filter(providers, { external: true });

export const uscMembershipTypes = [
  { id: 'M', name: 'M' },
  { id: 'L', name: 'L' },
  { id: 'XL', name: 'XL' },
];

export const fitnessGoals = [
  { id: 'burn_calories', name: 'Burn Calories' },
  { id: 'get_stronger', name: 'Get Stronger' },
  { id: 'improve_fitness', name: 'Improve fitness' },
  { id: 'train_smarter', name: 'Train Smarter' },
];
export const fitnessGoalLevels = [
  { id: 'unfit', name: 'Unfit' },
  { id: 'average', name: 'Average' },
  { id: 'fit', name: 'Fit' },
];
